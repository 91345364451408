import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-network-wired',
    title: 'Data Pipeline Solutions',
    description: 'We develop advanced data pipelines that seamlessly integrate with your systems, creating bespoke solutions. These pipelines enhance your data processing capabilities, providing a competitive advantage tailored to your needs.'
  },
  {
    id: 2,
    icon: 'fas fa-layer-group',
    title: 'Integrated Data Management',
    description: 'Our data management services ensure smooth interaction between various data sources and your existing infrastructure. By optimizing data flow and accuracy, we enhance decision-making and operational efficiency.'
  },
  {
    id: 3,
    icon: 'fas fa-brain',
    title: 'Dynamic Data Processing',
    description: 'We employ cutting-edge technologies to process data in real-time, integrating diverse data streams to provide timely and relevant insights. Our stream processing expertise ensures outputs that deliver a distinct competitive edge.'
  },
  {
    id: 4,
    icon: 'fas fa-users-cog',
    title: 'On-Demand Data Engineering Talent',
    description: 'Access our curated network of top-tier data engineers, data scientists, ETL specialists, and more whenever you need them. Our on-demand service ensures you have the right expertise to drive your projects forward efficiently and effectively.'
  },
  {
    id: 5,
    icon: 'fas fa-exchange-alt',
    title: 'Data Migration',
    description: 'We uphold the integrity of your data during the migration process, using robust techniques and tools. We also transform your data, refining it into more usable and valuable formats suitable for analysis and decision-making.'
  },
  {
    id: 6,
    icon: 'fas fa-cogs',
    title: 'Automated Data-Driven Processes',
    description: 'We uphold the integrity of your data during the migration process, using robust techniques and tools. We also transform your data, refining it into more usable and valuable formats suitable for analysis and decision-making.'
  }
]

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our services</h2>
          <div className="subtitle">services we provide</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;