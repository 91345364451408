import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/mission.jpg'),
    title: 'Our Mission',
    description: "Deliver innovative Data solutions that drive growth and efficiency.",
    v: 'https://www.google.com'
  },
  {
    id: 2,
    image: require('../assets/images/vision.jpg'),
    title: 'Our Vision',
    description: 'We envision a world where technology and business strategies are seamlessly integrated to drive sustainable growth. Our goal is to lead the way in digital transformation, helping organizations stay ahead in an ever-evolving technological landscape.',
    link: 'https://www.facebook.com'
  },
  {
    id: 3,
    image: require('../assets/images/values.jpg'),
    title: 'Our Values',
    description: 'Integrity, Innovation, Customer-Centricity, Collaboration, Excellence, Respect, Sustainability',
    link: 'https://www.twitter.com'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    {/* <a className="btn btn-primary" href={hero.link}>Learn More <i className="fas fa-chevron-right"></i></a> */}
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;