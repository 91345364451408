import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/images/logo.png';

function AppHeader() {
  return (
    <Navbar bg="light">
      <Container style={{ marginLeft: '0px', marginRight: '2px' }}>
        <Navbar.Brand href="#home" style={{ marginLeft: '0px' }}>
          <Image 
            src={logo} 
            alt="Logo"
            width="100"   
            height="100"
            className="d-inline-block align-top align-left"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            {/* <Nav.Link href="#works">Works</Nav.Link> */}
            {/* <Nav.Link href="#teams">Teams</Nav.Link> */}
            {/* <Nav.Link href="#testimonials">Testimonials</Nav.Link> */}
            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            {/* <Nav.Link href="#blog">Blog</Nav.Link> */}
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;