import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
// import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/images/aboutus.jpg';

function AppAbout() {
  // const html = 80;
  // const responsive = 95;
  // const photoshop = 60;

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
          <div className="subtitle">learn more about us</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>We create an all-inclusive end-to-end data infrastructure for your organization. This infrastructure allows you to store, change, and use your data to its maximum potential in a straightforward manner.</p>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae alias ex error distinctio laudantium recusandae, iure. Vel nisi soluta, minus consectetur reiciendis laborum, laudantium perspiciatis quos molestias quam eum.</p> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;